<template>
  <div class="boxes">
    <router-link
      tag="div"
      v-for="(box, index) in boxes" 
      :key="index"
      v-ripple="{ class: 'primary--text' }"
      :to="box.to"
    >
       <img :src="box.img" alt="">
        <p>{{box.title}}</p>
    </router-link>
  </div>
</template>

<script>
export default {
  data: () => ({
    boxes: [
      {title: 'Проживание', img: require('@/assets/images/administration/residence.svg'), to: '/administration/sales/residence'},
      {title: 'Ж/Д', img: require('@/assets/images/administration/trains.svg'), to: '/administration/sales/trains'},
      {title: 'Автобус', img: require('@/assets/images/administration/buses.svg'), to: '/administration/sales/buses'},
      {title: 'Экскурсии', img: require('@/assets/images/administration/excursions.svg'), to: '/administration/sales/tours'},
      {title: 'Трансфер', img: require('@/assets/images/administration/transfer.svg'), to: '/administration/sales/transfer'},
    ]
  }),
}
</script>

<style lang="scss" scoped>
.boxes {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 12px;
  justify-content: center;
  align-items: center;
  div {
    max-width: 193px;
    height: 120px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.1);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    p {
      font-family: 'Gilroy', sans-serif;
      margin-top: 16px;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 25px;
    }
  }
}
</style>